.layout-content-wrapper {
    padding: 2rem;
}

.task-list-dialogue {
    .p-button:focus {
        box-shadow: none !important;
    }

    .task-list {
        .p-datatable-thead {
            display: none;
        }
    }
}
