/* Utils */
.clearfix:after {
    content:" ";
    display:block;
    clear:both;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 70px;
    }
}

.auth-bg {
    background-color: #f7f7f7;
    background-position: 0 -150px;
}

.layout-menuitem-text{
    font-size: 12px;
}

.block-viewer {
    .block-section {
        margin-bottom: 4rem;
        overflow: hidden;
    }

    .block-header {
        padding: 2rem 2rem;
        background-color: var(--surface-section);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border:1px solid var(--surface-d);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .block-title {
            font-weight: 700;
            display: inline-flex;
            align-items: center;

            .badge-free {
                border-radius: 4px;
                padding: .25rem .5rem;
                background-color: var(--orange-500);
                color: white;
                margin-left: 1rem;
                font-weight: 700;
                font-size: .875rem;
            }
        }

        .block-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            margin-left: 1rem;

            a,button {
                display: flex;
                align-items: center;
                margin-right: .75rem;
                padding: .5rem 1rem;
                border-radius: 4px;
                font-weight: 600;
                border: 1px solid transparent;
                transition: background-color .2s;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:not(.block-action-disabled):hover {
                    background-color: var(--surface-c);
                }

                &.block-action-active {
                    border-color: var(--primary-color);
                    color: var(--primary-color);
                }

                &.block-action-copy {
                    i {
                        color: var(--primary-color);
                        font-size: 1.25rem;
                        margin: 0;
                    }
                }

                &.block-action-disabled {
                    opacity: .6;
                    cursor: auto !important;
                }

                i {
                    margin-right: .5rem;
                }
            }
        }
    }

    .block-content {
        padding: 0;
        border:1px solid var(--surface-d);
        border-top: 0 none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        overflow: hidden;

        pre.app-code {
            background-color: var(--surface-card) !important;
            padding-bottom: 1rem !important;
            margin-bottom: 0 !important;

            code {
                padding: 1rem 2.5rem;
            }
        }
    }

    @media screen and (max-width: 575px) {
        .block-header {
            flex-direction: column;
            align-items: start;

            .block-actions {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }
}

.p-reorderable-column{
    &:hover{
        cursor: default;
    }
}

.filter-card-footer{
    bottom: 0;
    position: fixed;
    width: 400px;
    padding: 20px;
    text-align:right;
    background-color: var(--surface-0);

}

.filter.filter-card-footer{
    left: 0;
}

.p-input-icon-left{
    i {
        width: 20px
    }
}

.text-end,
.text-right{
    text-align: right;
}

.react-datepicker-wrapper,
.reactdatepicker-container {
    width: 100%;
}

.p-datatable .p-datatable-tbody > tr > td{
    //padding: 0.7rem 1rem;
    padding: 0.5rem;
}

// to handle small size for select box
.p-inputtext-sm.p-multiselect .p-multiselect-label{
    padding: 0.488rem 0.75rem;
}

.p-inputtext-sm.p-multiselect .p-multiselect-label{
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;

    .p-multiselect-token{
        font-size: 0.875rem;
        padding: 0.175rem 0.65rem;
    }
}

.adjust-height .p-colorpicker-preview{
    height: 38px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.right-border-radius-erash{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

// to handle small size for select box
.p-inputtext-sm.p-treeselect .p-treeselect-label{
    padding: 0.488rem 0.75rem;
}

.p-inputtext-sm.p-treeselect .p-treeselect-label{
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;

    .p-treeselect-token{
        font-size: 0.875rem;
        padding: 0.175rem 0.65rem;
    }
}


@media screen and (max-width: 820px) {
    .filter-card-footer{
        position: static;
        width: auto !important;
        text-align: left;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid var(--surface-d);
    }
}

.custom-field {
    .p-chip {
        display: initial;
    }

    .p-chips .p-chips-multiple-container {
        padding: 0.175rem 0.75rem;
    }

    .p-chips-input-token input,
    .p-chips .p-chips-multiple-container .p-chips-token {
         font-size: 0.875rem;
         padding: 0.175rem 0.65rem;
     }

    .p-inputtext.p-chips-multiple-container{
        width: 100%;
    }
}

.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
}

.fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
    white-space: normal;
}

.fl-table th, .fl-table td {
    border: 1px solid var(--surface-300);
}


/* Responsive */

@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }
    .table-wrapper:before{
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }
    .fl-table thead, .fl-table tbody, .fl-table thead th {
        display: block;
    }
    .fl-table thead th:last-child{
        border-bottom: none;
    }
    .fl-table thead {
        float: left;
    }
    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }
    .fl-table td, .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid var(--surface-300);
    }
    .fl-table tbody tr {
        display: table-cell;
    }
    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}

.text-bold {
    font-weight: 600 !important;
}

.text-danger {
    color: #FCA5A5;
}

.text-secondary {
    color: var(--blue-300);
}

.text-success {
    color: var(--green-300);
}

.p-inputgroup-addon.p-radio-sm {
    padding: 0;

    .p-radiobutton {
        width: 20px;
        height: 20px;
    }
}

.border-info {
    border-color: var(--green-300);
}

.font-size-12px {
    font-size: 12px;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.quick-filter-container{
    fieldset > legend > a {
        padding: 0.4rem;
        font-size: 0.875rem;
    }

    fieldset {
        .p-fieldset-content{
            padding: 0.3rem;
        }
    }
}

.p-button.p-button-x-sm {
    font-size: 0.675rem;
    padding: 0.35625rem 0.6375rem;
}

.text-xxs {
    font-size: 0.675rem;
}

.p-card.content-p-0 {
    .p-card-body,
    .p-card-content {
        padding: 0;
    }
}

.mb-7 {
    margin-bottom: 6rem !important;
}

.justify-content-space-between{
    justify-content: space-between;
}

//.spot-order-container{
//    .p-panel-toggleable{
//        .p-panel-header{
//            display: block;
//            width: 100%;
//        }
//
//        .p-panel-icons{
//            display: none;
//        }
//    }
//
//
//    .spot-load-a-h {
//        position: relative;
//        top: 5px;
//    }
//
//    .spot-load-a-h-group-checkbox {
//        position: relative;
//        top: -2px;
//    }
//
//    .spot-load-a-h-status-icons {
//        position: relative;
//        top: -6px;
//    }
//
//}

.max-width-300px {
    max-width: 300px;
}

.hide-p-toggler {
    .p-tree-toggler.p-link{
        display: none;
    }
}

.list-type-none {
    list-style: none;
    padding-left: 0;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--primary-color);
    display: grid;
    animation: l22-0 2s infinite linear;
    position: relative;
    z-index: 15000;
}

.loader:before,
.loader:after {
    content: "";
    grid-area: 1/1;
    margin: 15%;
    border-radius: 50%;
    background: inherit;
    transform: rotate(0deg) translate(150%);
    animation: l22 1s infinite;
    position: relative;
    z-index: 15000;
}

.loader:after {
    animation-delay: -.5s
}

@keyframes l22-0 {
    100% {transform: rotate(1turn)}
}

@keyframes l22 {
    100% {transform: rotate(1turn) translate(150%)}
}

#header-search-vin{
    .position-relative {
        position: relative;
    }

    .position-relative.custom-tag-search-container {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .custom-tag-multi-value-container {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        cursor: text;
        height: 47px !important;
        padding: 7px 12px 10px 10px;
        max-width: 342px;
        overflow-y: auto;
        width: 100%;
        margin-bottom: 0px;

        li.custom-tag-value-item {
            background-color: var(--primary-color);
            color: var(--primary-color-text);
            font-size: 12px;
            margin-right: 5px;
            padding: 5px 10px;
            border-radius: 5px;
            margin-bottom: 5px;
        }

        input.custom-tag-search-input {
            cursor: text;
            border: none;
            font-size: 14px;
            background-color: transparent;
            &:active,
            &:focus,
            &:focus-visible{
                outline: none;
            }
        }

        .close-indicator {
            color: black;
            padding: 0;
            margin: 0;
            outline: none;
            line-height: 0;
            background: none;
            border: 0;

            &:hover {
                cursor: pointer;
            }
        }

        .close-indicator-main {
            position: absolute;
            right: 18px;
            top: 50%;
            background: none;
            border: none;
            padding: 2px;
            font-size: 12px;

            &:hover i {
                color: red;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .btn-vin-search {
        border: none;
        position: absolute;
        right: 16px;
        top: 30%;
        transform: translateY(-50%);
        background: transparent;
        padding: 2px;
        font-size: 10px;

        &:hover i {
            color: #1de9b6;
        }

        &:hover {
            cursor: pointer;
        }
    }

    @media (max-width: 991px) {
        .custom-tag-container {
            flex: 1;
        }

        ul.custom-tag-multi-value-container li.custom-tag-value-item {
            background-color: #e6e9ee !important;
            color: #000000;
        }
    }
}

.helper-speed-dial.p-speeddial {
    position: fixed !important;
    right: 5px;
    bottom: 10px;
}

.chat-window {
    width: 40vw;

    @media (max-width: 1160px) {
        width: 55vw;
    }

    @media (max-width: 870px) {
        width: 100vw;
    }

    .p-tabview-panels{
        padding: 0;
        padding-top: 3px;
    }
}

.spot-load-global-driver-target {
    .p-checkbox.p-component{
        display: none;
    }
}

.fl-card {
    .p-card-content{
        padding-top: 0;
    }
}

.position-minus {
    position: fixed;
    bottom: -10000%;
}


.p-dropdown-item.p-focus{
    color: #1e293b;
    background: #f1f5f9;
}

//.p-multiselect-checkbox{
//    display:none;
//}


.m-timeline {
    position: relative;
    height: 100%;
    margin: {
        left: auto;
        right: auto;
    }

    div {
        &:after {
            content: '';
            width: 2px;
            position: absolute;
            top: .5rem;
            bottom: 0rem;
            left: 60px;
            z-index: 1;
            background: #C5C5C5;
        }
    }

    h3 {
        position: -webkit-sticky;
        position: sticky;
        top: 5rem;
        color: #888;
        margin: 0;
        font: {
            size: 1em;
            weight: 400;
        }
        @media (min-width: 62em) {
            font-size: 1.1em;
        }
    }
    section.year {
        position: relative;
        &:first-child section{
            margin-top: -1.3em;
            padding-bottom: 0px;
        }
        section {
            position: relative;

            ul {
                list-style-type: none;
                padding: 0 0 0 75px;
                margin: -1.35rem 0 1em;
                @media (min-width: 62em) {
                    padding: 0 0 0 81px;
                }
                &:last-child {
                    margin: {
                        bottom: 0;
                    }
                }
                &:first-of-type:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #C5C5C5;
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                    //@include border-radius(50%);
                    position: absolute;
                    left: 54px;
                    top: 3px;
                    z-index: 2;
                }
                li {
                    margin-left: .5rem;
                    &:before {
                        content: '·';
                        margin-left: -.5rem;
                        padding-right: .3rem;
                    }
                    &:not(:first-child) {
                        margin-top: .5rem;
                    }
                    span.price {
                        color: mediumturquoise;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
